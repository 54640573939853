import {Box, Flex, Heading, Image, Link, Stack, Text} from "@chakra-ui/react";
import React from "react";
import {FaGithub, FaGitlab, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {logo} from "./assets/img";
import Transy from "./Transy";

const App = () => {
	return (
		<>
			<Transy />

			<Flex justifyContent={"center"} alignItems={"center"} height={"100vh"} p={40}>
				<Stack spacing={30} alignItems={"center"}>
					<Image src={logo} alt={"Logo"} maxWidth={350} />

					<Stack alignItems={"center"} spacing={10} textAlign={"center"}>
						<Stack alignItems={"center"} spacing={0} textAlign={"center"}>
							<Heading color={"white"} size={"2xl"} fontWeight={500}>Koen Brouwer</Heading>
							<Text color={"white"} fontSize={"25px"}>Fullstack Web Developer and Technical Lead</Text>
						</Stack>
						<Text fontSize={"20px"} color={"white"}>
							React, Typescript, NodeJS, GraphQL, PostgreSQL, Docker, Kubernetes... and lots more.
						</Text>
					</Stack>

					<Stack direction={"row"} alignItems={"center"} mt={10} spacing={8}>
						<Link href={"https://twitter.com/koenbrouwer/"} target={"_blank"} rel={"noopener noreferrer"} title={"Twitter"}>
							<Box as={FaTwitter} size={"32px"} fill={"white"} verticalAlign={"middle"} />
						</Link>
						<Link href={"https://linkedin.com/in/koenbrouwer/"} target={"_blank"} rel={"noopener noreferrer"} title={"LinkedIn"}>
							<Box as={FaLinkedinIn} size={"32px"} fill={"white"} verticalAlign={"middle"} />
						</Link>
						<Link href={"https://koenenelmarshow.nl/"} target={"_blank"} rel={"noopener noreferrer"} title={"Koen en Elmar show"}>
							<Text fontSize={"32px"}><span role={"img"}>🎙</span></Text>
						</Link>
						<Link href={"https://gitlab.com/koenbrouwer/"} target={"_blank"} rel={"noopener noreferrer"} title={"GitHub"}>
							<Box as={FaGitlab} size={"32px"} fill={"white"} verticalAlign={"middle"} />
						</Link>
						<Link href={"https://github.com/koenbrouwer/"} target={"_blank"} rel={"noopener noreferrer"} title={"GitHub"}>
							<Box as={FaGithub} size={"32px"} fill={"white"} verticalAlign={"middle"} />
						</Link>
					</Stack>
				</Stack>
			</Flex>
		</>
	);
};

export default App;
