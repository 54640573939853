import {Box, BoxProps} from "@chakra-ui/react";
import React from "react";
import {background} from "./assets/img";

const styles: BoxProps = {
	position: "fixed",
	width: "100%",
	height: "100%",
	zIndex: -1,
	opacity: .2,
	backgroundSize: "cover",
	backgroundImage: `url(${background})`,
	backgroundPosition: "center center",
	filter: "blur(7px) grayscale(100%)",
};

const Transy = () => {
	return (
		<Box {...styles} />
	);
};

export default Transy;
