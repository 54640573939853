import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";

const theme = extendTheme({
	breakpoints: ["30em", "48em", "62em", "80em"],
	fonts: {
		heading: "\"Roboto Slab\", sans-serif",
		body: "system-ui, sans-serif",
		mono: "Menlo, monospace",
	},
	fontSizes: {
		xs: "0.75rem",
		sm: "0.875rem",
		md: "1rem",
		lg: "1.125rem",
		xl: "1.25rem",
		"2xl": "1.5rem",
		"3xl": "1.875rem",
		"4xl": "2.25rem",
		"5xl": "3rem",
		"6xl": "4rem",
	},
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</React.StrictMode>,
);
